/** @format */
/**
 * Para realizar prueba en local, recomuendo reemplazar la variable rootUrl
 * por la url del servidor local o de test, ejemplo
 * Test: "https://woodstock.test.test-forus-sistemas.biz"
 * Local: "http://localhost:8080/"
 */

import {getUrl} from './country';

const country = getUrl();

const rootUrl = `${country.url}`

const isChile = country.id !== "backoffice-dev" && country.id !== "backoffice-test";

const urlService = isChile ?
                   `${rootUrl}/api-backend-woodstock/` :
                   `${rootUrl}/backend/`;

const urlApiUtils = `${rootUrl}/api-utils/`;
// const urlApiUtils = "http://localhost:8080/api-utils/";

const cargaArchivos = `${rootUrl}/generador-archivos-correo/`;
// const cargaArchivos = "http://localhost:8083/generador-archivos-correo/";

const courierService = `${rootUrl}/courier/`;
// const courierService = "http://localhost:8083/courier/";

const pedidosService = `${rootUrl}/pedidos/`;
// const pedidosService = "http://localhost:8083/pedidos/";

const apiQuiebres = `${rootUrl}/quiebrestock/`;
//const apiQuiebres = "http://localhost:8080/quiebrestock/"

const bluexService = `${rootUrl}/bluex/orden/`;
// const bluexService = "http://a78cd5d321fef4762a4c885a60a99299-77308d69957eb4e8.elb.us-east-1.amazonaws.com/bluex/orden/";
// const bluexService = "http://localhost:8080/orden/";
/*
 *  URL para estado de los servicios core de forus app
 */
const courierStatusUrl = `${courierService}actuator/health`;
const apiBackendStatusUrl = `${urlService}actuator/health`;
const apiUtilsStatusUrl = `${urlApiUtils}actuator/health`;
const apiCargaArchivosCorreosStatusUrl = `${cargaArchivos}actuator/health`;
const apiPedidosStatusUrl = `${pedidosService}actuator/health`;
const bluexStatusUrl = `${bluexService}actuator/health`;

export {
  urlService,
  urlApiUtils,
  cargaArchivos,
  courierStatusUrl,
  bluexStatusUrl,
  apiBackendStatusUrl,
  apiUtilsStatusUrl,
  apiCargaArchivosCorreosStatusUrl,
  apiPedidosStatusUrl,
  apiQuiebres,
};

