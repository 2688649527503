import  { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {Row,Col} from 'antd'
import api from "./../../api";

const MainFooter = ({ contained, copyright, country }) => {
  const [backStatus, setBackStatus] = useState(true);
  const [courierStatus, setCourierStatus] = useState(true);
  const [generadorCorreosStatus, setGeneradorCorreosStatus] = useState(true);
  const [apiUtilsStatus, setApiUtilsStatus] = useState(true);
  const [apiPedidosStatus, setApiPedidosStatus] = useState(true);
  const [totalPromoNoConfig, setTotalPromoNoConfig] = useState(true);
  const [bluexStatus, setBluexStatus] = useState(true);

  const {
    backend,
    correosChile,
    blueExpress,
    informesBackend,
    manifiestosBackend,
    divisionPedidos,
    promociones,
  } = country.statusServerBackendFooter;

  useEffect(() => {
    backend && getStatus();
    correosChile && getStatusCourier();
    manifiestosBackend && getStatusArchivosCorreo();
    informesBackend && getStatusApiUtils();
    divisionPedidos && getStatusPedidos();
    promociones && getTotalPromoNoConfig(2);
    blueExpress && getStatusBluex();
  }, []);

  const serverStatus = (status) => {
    const statusColor = status ? { color: "green" } : { color: "red" };
    return (
      <i className="material-icons" style={{...statusColor,fontSize:16}}>
        {status ? "check_circle" : "report_problem"}
      </i>
    );
  };

  //Status para promociones no configuradas
  const promoStatus = (totalPromoNo) => {
    const statusColor =
      totalPromoNo === 0 ? { color: "green" } : { color: "red" };
    return (
      <i className="material-icons" style={{...statusColor,fontSize:16}}>
        {totalPromoNo === 0 ? "check_circle" : "report_problem"}
      </i>
    );
  };

  const getStatus = async () => {
    const statusApi = await api.apiBackendStatus();
    if (statusApi && statusApi.status) {
      setBackStatus(statusApi.status === "UP");
    } else {
      setBackStatus(statusApi);
    }
  };

  const getStatusCourier = async () => {
    const statusApi = await api.apiCourierStatus();
    if (statusApi && statusApi.status) {
      setCourierStatus(statusApi.status === "UP");
    } else {
      setCourierStatus(statusApi);
    }
  };

  const getStatusBluex = async () => {
    const statusApi = await api.apiBluexStatus();
    console.log("status Bluex", statusApi);
    if (statusApi && statusApi.status) {
      setBluexStatus(statusApi.status === "UP");
    } else {
      setBluexStatus(statusApi);
    }
  };

  const getStatusArchivosCorreo = async () => {
    const statusApi = await api.apiCargaArchivosCorreoStatus();
    if (statusApi && statusApi.status) {
      setGeneradorCorreosStatus(statusApi.status === "UP");
    } else {
      setGeneradorCorreosStatus(statusApi);
    }
  };

  const getStatusApiUtils = async () => {
    const statusApi = await api.apiUtilsStatus();
    if (statusApi && statusApi.status) {
      setApiUtilsStatus(statusApi.status === "UP");
    } else {
      setApiUtilsStatus(statusApi);
    }
  };

  const getStatusPedidos = async () => {
    const statusApi = await api.apiPedidosStatus();
    if (statusApi && statusApi.status) {
      setApiPedidosStatus(statusApi.status === "UP");
    } else {
      setApiPedidosStatus(statusApi);
    }
  };

  const getTotalPromoNoConfig = async (opt) => {
    const statusApi = await api.apiObtenerTotalPromocionesNoConfig(opt);
    if (statusApi) {
      setTotalPromoNoConfig(statusApi.data.totalPromocionesNoconfig);
    } else {
      setTotalPromoNoConfig(999);
    }
  };

  return (
    <footer
      style={{minHeight:50}}
      className="main-footer d-flex py-1 pb-0 px-0 bg-white border-top align-items-center"
    >
      <div className="container-fluid position-relative overflow-hidden ">
        <Row>
          <Col xs={24} md={20} className="d-flex align-items-center">
            <Row className="gap-2">
              {backend && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Backend: {serverStatus(backStatus)}
                  </span>
                </Col>
              )}
              {correosChile && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Correos de Chile: {serverStatus(courierStatus)}
                  </span>
                </Col>
              )}
              {blueExpress && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Blue Express: {serverStatus(bluexStatus)}
                  </span>
                </Col>
            )}
            {informesBackend && (
              <Col>
                <span className="ml-auto d-flex align-items-center">
                  Informes: {serverStatus(apiUtilsStatus)}
                </span>
              </Col>
            )}
            {manifiestosBackend && (
              <Col>
                <span className="ml-auto d-flex align-items-center">
                  Manifiestos: {serverStatus(generadorCorreosStatus)}
                </span>
              </Col>
            )}
            {divisionPedidos && (
              <Col>
                <span className="ml-auto d-flex align-items-center">
                  División de Pedidos: {serverStatus(apiPedidosStatus)}
                </span>
              </Col>
            )}
            {promociones && (
              <Col>
                <span className="ml-auto d-flex align-items-center">
                  Promociones sin Configurar {totalPromoNoConfig}:{" "}
                  {promoStatus(totalPromoNoConfig)}
                </span>
              </Col>
            )}
            </Row>
          </Col>
          <Col xs={24} md={4} className="d-flex my-3 my-lg-0 justify-content-center">
            <span className="copyright">{copyright}</span>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: true,
  copyright: `${new Date().getFullYear()} - Forus Digital Factory`,
};

const mapStateToProps = (state) => ({
  country: state.app.country,
  navbarStatus: state.app.navbarStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainFooter);
