/** @format */

import  { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import { useCollapse } from 'react-collapsed'
import { setMenuMobile } from "../../../action";
import { connect } from "react-redux";
import Icon from "../../ui/table/Icon";

const SidebarNavCollapseItem = (props) => {

  let location = useLocation();
  const { item, hideLogoText ,handleSetMenuMobile } = props;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  const isActive = useMemo(() => location.pathname === item.path, [location])

  return (
    <>
      {item.child ? (
        <div>
          <li className="nav-item d-flex align-items-center" style={{height:50}}>
            <a
              className={`nav-link color-text-nav d-flex align-items-center justify-content-between flex-grow-1 ${isActive ? 'active' : ''}`}
              {...getToggleProps()}
            >
              <div className="d-flex align-items-center gap-2" >
                {item?.htmlBefore && (
                  <div className="d-flex">
                    <Icon name={item?.htmlBefore} />
                  </div>
                )}
                {hideLogoText && item.title && (
                  <span>{item.title}</span>
                )}
              </div>
              {!isExpanded ?
                <i className="material-icons" style={{fontSize:18}}>expand_more</i>
                :
                <i className="material-icons" style={{fontSize:18}}>expand_less</i>
              }
            </a>
          </li>
          <div {...getCollapseProps()}>
            {item.childs.map((url, idx) => {
              return (
                <li className="nav-item my-2 ms-4" key={idx}>
                  <NavLink to={url.to} className="nav-link color-text-nav">
                    {url.title && <span>{url.title}</span>}
                  </NavLink>
                </li>
              );
            })}
          </div>
        </div>
      ) : (
        <li className="nav-item d-flex align-items-center" style={{height:50}}>
          <NavLink 
            to={item.to} 
            className={`nav-link color-text-nav d-flex align-items-center gap-2 flex-grow-1 ${isActive ? 'active' : ''}` }
            onClick={() => handleSetMenuMobile(false)}
          >
            {item?.htmlBefore && (
              <div className="d-flex">
                <Icon name={item?.htmlBefore} />
              </div>
            )}
            {hideLogoText && item.title && (
              <span>{item.title}</span>
            )}
          </NavLink>
        </li>
      )}
    </>
  );
}

SidebarNavCollapseItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetMenuMobile: (payload) => {
    dispatch(setMenuMobile(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavCollapseItem)
