/** @format */

import { Suspense, lazy, useEffect, useMemo } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import withTracker from "../withTracker";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { getRoutes, getSidebar } from "../utils/funcRoutes";
import { setNavbarItem } from "../action";
import LayoutSidebar from "../layouts/LayoutSidebar";
import LoadingLazy from "../components/LoadingLazy";

const Routes = (props) => {

  const { usuario, updateListNavbar } = props
  const { roles } = usuario || {}

  const listRoutes = useMemo(() => getRoutes(roles), [roles])

  useEffect(() => {
    if (roles) {
      const listNavbar = getSidebar(roles);
      updateListNavbar(listNavbar);
    }
  }, [roles, updateListNavbar])

  const LoginLazy = lazy(() => import('../views/Login/Login'))
  const routersLazy = [
    {
      id: "IndexOrdenes",
      component: lazy(() => import('../views/Ordenes/Index')),
    },
    {
      id: "IndexOrderView",
      component: lazy(() => import('../views/Ordenes/OrderView')),
    },
    {
      id: "IndexOrdenProblema",
      component: lazy(() => import('../views/OrdenesProblemas/IndexOrdenProblema')),
    },
    {
      id: "UserProfileLite",
      component: lazy(() => import('../views/UserProfile/UserProfileLite')),
    },
    {
      id: "IndexTienda",
      component: lazy(() => import('../views/Tienda/Index')),
    },
    {
      id: "IndexUsuario",
      component: lazy(() => import('../views/Usuario/Index')),
    },
    {
      id: "IndexVendedores",
      component: lazy(() => import('../views/Usuario/IndexVendedores')),
    },
    {
      id: "IndexUsuarioAdd",
      component: lazy(() => import('../views/Usuario/Add')),
    },
    {
      id: "IndexTiendaAdd",
      component: lazy(() => import('../views/Tienda/Add')),
    },
    {
      id: "IndexTiendaEdit",
      component: lazy(() => import('../views/Tienda/edit')),
    },
    {
      id: "IndexLogTienda",
      component: lazy(() => import('../views/Tienda/log')),
    },
    {
      id: "IndexOperadoresLogistico",
      component: lazy(() => import('../views/Tienda/operadorLogistico/operadoresLogistico')),
    },
    {
      id: "IndexEditOperadorLogistico",
      component: lazy(() => import('../views/Tienda/operadorLogistico/editOperadorLogistico')),
    },
    {
      id: "IndexRetiro",
      component: lazy(() => import('../views/RetiroTienda/IndexRetiro')),
    },
    {
      id: "FinalizarOrdenes",
      component: lazy(() => import('../views/RetiroTienda/FinalizaOrdenes')),
    },
    {
      id: "CentrosDistribucion",
      component: lazy(() => import('../views/centrosDistribucion/view')),
    },
    {
      id: "PrioridadView",
      component: lazy(() => import('../views/Prioridad/view')),
    },
    {
      id: "EditarZonaView",
      component: lazy(() => import('../views/Prioridad/EditarZona')),
    },
    {
      id: "BlackListView",
      component: lazy(() => import('../views/ListaNegra/BlackListView')),
    },
    {
      id: "DetailBlackList",
      component: lazy(() => import('../views/ListaNegra/DetailBlackList')),
    },
    {
      id: "IndexCx",
      component: lazy(() => import('../views/OrdenesCX/IndexCx')),
    },
    {
      id: "EditarUsuario",
      component: lazy(() => import('../views/Usuario/EditarUsuario')),
    },
    {
      id: "etiquetaManifiesto",
      component: lazy(() => import('../views/etiquetaManifiesto')),
    },
    {
      id: "comunasVtex",
      component: lazy(() => import('../views/ComunasVtex')),
    },
    {
      id: "IndexComunaVtexAdd",
      component: lazy(() => import('../views/ComunasVtex/Add')),
    },
    {
      id: "mantenedorPromociones",
      component: lazy(() => import('../views/MantenedorPromociones')),
    },
    {
      id: "IndexPromocionAdd",
      component: lazy(() => import('../views/MantenedorPromociones/Add')),
    },
    {
      id: "alarmaPromociones",
      component: lazy(() => import('../views/AlarmaPromociones')),
    },
    {
      id: "mantenedorSku",
      component: lazy(() => import('../views/MantenedorSku')),
    },
    {
      id: "Clientes",
      component: lazy(() => import('../views/Clientes')),
    },
    {
      id: "ClienteView",
      component: lazy(() => import('../views/Clientes/ClienteView')),
    },
    {
      id: "FechaComprometidaView",
      component: lazy(() => import('../views/FechaComprometida/view')),
    },
    {
      id: "EditFecha",
      component: lazy(() => import('../views/FechaComprometida/edit')),
    },
    {
      id: "MarketPlaceList",
      component: lazy(() => import('../views/Marketplace/List')),
    },
    {
      id: "MarketPlaceDetail",
      component: lazy(() => import('../views/Marketplace/Detail')),
    },
    {
      id: "ChainDiscount",
      component: lazy(() => import('../views/OrdenesCX/chainDiscount')),
    },
    {
      id: "ChainDiscountAdd",
      component: lazy(() => import('../views/OrdenesCX/chainDiscount/Add')),
    },
    {
      id: "editPromoNoConfigNumEmail",
      component: lazy(() => import('../views/AlarmaPromociones/editPromoNoConfigNumEmail')),
    },
    {
      id: "MacroRule",
      component: lazy(() => import('../views/MacroRule/MacroRule')),
    },
    {
      id: "LogisticaInversa",
      component: lazy(() => import('../views/LogisticaInversa')),
    },
  ]

  return (
    <>
      {usuario && usuario._id ?
        <Switch>
          <LayoutSidebar>
            {listRoutes.map((route, index) => {

              /*
              * Si la ruta es lazy, se busca el componente en el array de routers
              * Si no es lazy, se busca el componente en el array de routes
              * Esto lo hacemos para poder lazy loading de componentes
              */
              const ComponentView = route.isLazy ?
                routersLazy.find(item => item.id === route.component)?.component
                : route.component

              return (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker((props) => {
                    return (
                      <route.layout {...props}>
                        <Suspense fallback={<LoadingLazy />}>
                          <ComponentView {...props} />
                        </Suspense>
                      </route.layout>
                    );
                  })}
                />
              );
            })}
          </LayoutSidebar>
        </Switch>
        :
        <Switch>
          <PublicRoute
            path="/Login"
            exact={true}
            component={(routeProps) => {
              return <Suspense fallback={<LoadingLazy />}>
                <LoginLazy {...routeProps} />
              </Suspense>
            }}
          />
          <PublicRoute
            path="*"
            exact={false}
            component={(routeProps) => <Redirect to={"/login"} />}
          />
        </Switch>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  usuario: state.app.usuario,
});

const mapDispatchToProps = (dispatch) => ({
  updateListNavbar: (list) => dispatch(setNavbarItem(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
